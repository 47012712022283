import { DASHBOARD_API_TYPE } from "app/shared/constants/dashboard";
import { useLanguage } from "app/shared/hooks";
import { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import { clearDashboardPassword, findNearestPlace } from "../helper";
import {
  CHECK_SAVE_MODE,
  PAGE_MODE,
  defaultH,
  defaultW,
  defaultW50,
  defaultWH,
  defaultX,
  defaultY,
} from "../constant";

const useDashboard = ({
  intl,
  workspaceDashboardApi,
  workspaceApi,
  stationApi,
}) => {
  const [language] = useLanguage();
  const history = useHistory();
  const [showMenu, setShowMenu] = useState(false);
  const [showTopLogo, setShowTopLogo] = useState(false);
  const [items, setItems] = useState([]);
  const [layout, setLayout] = useState();
  const [layoutOld, setLayoutOld] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [openStates, setOpenStates] = useState({
    VALUE: false,
    VALUE_LIST: false,
    STATIC_TEXT: false,
    ALARM_SUMMARY: false,
    ATTACHMENT_IMAGE: false,
    DIRECTION: false,
    HISTORIC_TABLE: false,
    PREDICTION_PARAMETER_HISTORY_TABLE: false,
    PREDICTION_PARAMETER_STANDARD_CHART: false,
    STANDARD_CHART: false,
    GAUGE_CHART: false,
    CUSTOM_CHART: false,
    LOCATION_MAP: false,
    AGGREGATE_TABLE: false,
  });
  const [configData, setConfigData] = useState({
    workspaceId: undefined,
    dashboardId: undefined,
    parameters: [],
    stations: [],
  });
  const [activeItem, setActiveItem] = useState({});
  const [data, setData] = useState({});
  const [open, setOpen] = useState({});
  const [realTimeData, setRealTimeData] = useState({});
  const [realTimeDataAlarm, setRealTimeDataAlarm] = useState({});
  const [pageLoading, setPageLoading] = useState(true);
  const [itemsLoading, setItemsLoading] = useState({});
  const [onPressCtrlKey, setOnPressCtrlKey] = useState(false);
  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const socketRef = useRef();
  const publicSocketRef = useRef();

  const [pageMode, setPageMode] = useState(PAGE_MODE.VIEW);
  const [screenMode, setScreenMode] = useState();
  const [screenSize, setScreenSize] = useState();
  const [viewMax, setViewMax] = useState();

  const [dashboardView, setDashboardView] = useState();
  const [reset, setReset] = useState(false);
  const [init, setInit] = useState(false);
  const [isRendering, setIsRendering] = useState(false);
  const [gridResizeEvent, setGridResizeEvent] = useState({
    isResized: true,
    cardId: null,
  });
  const screenEdit = () => {
    setLayoutOld(false);
    initLayout();
    if (pageMode === PAGE_MODE.ARRANGE) {
      for (let i = 0; i < dashboardView?.length; i++) {
        if (dashboardView[i].id === screenMode) {
          setScreenSize(dashboardView[i].width - 1);
          return;
        }
      }
    }
  };

  const modalConfirmSave = async (data, type) => {
    swal({
      title: intl.formatMessage({
        id: "CLIENT.DASHBOARD.MODAL.UPDATE_POSITION_WARNING_TITLE",
      }),
      text: intl.formatMessage({
        id: "CLIENT.DASHBOARD.MODAL.UPDATE_POSITION_WARNING_MESSAGE",
      }),
      icon: "warning",
      buttons: [
        intl.formatMessage({
          id: "CLIENT.GENERAL.NO",
        }),
        intl.formatMessage({
          id: "CLIENT.GENERAL.YES",
        }),
      ],
    }).then((yes) => {
      if (yes) {
        setLayoutOld(false);
        switch (type) {
          case CHECK_SAVE_MODE.NAVIGATE:
            history.push(data?.pathname);
            break;
          case CHECK_SAVE_MODE.SCREEN:
            onLayoutChange();
            setScreenMode(data);
            break;
          default:
            setPageMode(data);
        }
      }
    });
  };

  const activeScreen = (data) => {
    if (!layoutOld) {
      setInit(true);
      setScreenMode(data);
      setIsRendering(true);
    } else {
      modalConfirmSave(data, CHECK_SAVE_MODE.SCREEN);
    }
  };
  const setCheckPageMode = (data) => {
    if (!layoutOld) {
      setPageMode(data);
    } else {
      modalConfirmSave(data, CHECK_SAVE_MODE.PAGE);
    }
  };

  const setLayoutDashboard = (layout) => {
    if (!reset && !init) {
      if (pageMode === PAGE_MODE.ARRANGE) {
        setLayoutOld(true);
      }
      setLayout(layout);
    } else {
      setInit(false);
      setReset(false);
    }
  };

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const toggleModal = (type) => {
    setOpenStates({
      ...openStates,
      [type]: !openStates[type],
    });
  };

  const toggleModalPassword = () => {
    setOpenPasswordModal(!openPasswordModal);
    setPageLoading(false);
  };

  const handleCloseModalPassword = () => {
    setOpenPasswordModal(false);
  };

  const handleOpenModalPassword = () => {
    setOpenPasswordModal(true);
  };

  const updatePosition = async (position) => {
    try {
      const rq = await workspaceDashboardApi.updatePosition(position);
      if (rq?.success) {
        setLayoutOld(false);
        swal({
          title: intl.formatMessage({
            id: "CLIENT.DASHBOARD.MODAL.UPDATE_POSITION_SUCCESS_TITLE",
          }),
          text: intl.formatMessage({
            id: "CLIENT.DASHBOARD.MODAL.UPDATE_POSITION_SUCCESS_MESSAGE",
          }),
          icon: "success",
          button: intl.formatMessage({
            id: "CLIENT.GENERAL.OK_BUTTON",
          }),
        });
        getAllDashboard(false, true);
      }
    } catch (error) {
      swal({
        title: intl.formatMessage({
          id: "CLIENT.DASHBOARD.MODAL.UPDATE_POSITION_ERROR_TITLE",
        }),
        icon: "error",
        button: intl.formatMessage({
          id: "CLIENT.GENERAL.OK_BUTTON",
        }),
      });
    }
  };

  const updateItem = (id, data) => {
    const newDashboardData = items.map((item) => {
      if (item?.id === id) {
        return {
          ...item,
          data: {
            ...data,
            dashboard_position: item?.data?.dashboard_position,
          },
        };
      }

      return item;
    });

    setItems(newDashboardData);
  };
  const checkView = (w) => {
    let result = null;
    let idView;
    let max = dashboardView?.[0];
    for (let i = 0; i < dashboardView?.length; i++) {
      const data = dashboardView[i];
      if (data.width > w && (result === null || data.width < result)) {
        result = data.width;
        idView = data.id;
      }
      if (max.width < data.width) {
        max = data;
      }
    }
    if (idView === undefined && w > max?.width) {
      idView = max.id;
    }
    return idView;
  };
  const handleResize = () => {
    if (pageMode !== PAGE_MODE.ARRANGE) {
      setScreenMode(checkView(window.innerWidth));
    }
  };
  const getAllDashboard = async (haveLoading, resetPage) => {
    try {
      if (haveLoading) {
        setPageLoading(true);
      }
      const response = await workspaceDashboardApi.getAllDashboard(
        configData.dashboardId
      );
      if (response.success) {
        const resItems = response.data.dashboard
          .filter((item) => item.dashboard_type_code !== null)
          .map((item) => ({
            data: {
              ...item,
            },
            type: item.dashboard_type_code,
            id: item.id,
            x: item.x,
            y: item.y,
            w: item.w,
            h: item.h,
            minH: 1,
          }));
        setItems(resItems);
        setData(response.data);
        if (!resetPage) {
          setPageMode(PAGE_MODE.VIEW);
        }
      }
    } catch (error) {
      // console.log(error);
      history.push("/not-found");
    } finally {
      if (haveLoading) {
        setPageLoading(false);
      }
    }
  };

  const solveErrorPublicDashboard = (error) => {
    if (
      error?.response?.status === 403 &&
      error?.response?.data?.error === "Incorrect password"
    ) {
      swal({
        title: intl.formatMessage({
          id: "GENERAL.SOMETHING_WENT_WRONG",
        }),
        icon: "error",
        button: intl.formatMessage({
          id: "CLIENT.GENERAL.OK_BUTTON",
        }),
      }).then(handleOpenModalPassword);
    } else {
      // status = 404 or Unknown error
      swal({
        title: intl.formatMessage({
          id: "GENERAL.SOMETHING_WENT_WRONG",
        }),
        icon: "error",
        button: intl.formatMessage({
          id: "CLIENT.GENERAL.OK_BUTTON",
        }),
      }).then(() => history.push("/not-found"));
    }
  };

  const getAllPublicDashboard = async (id, pass) => {
    try {
      const response = await workspaceDashboardApi.getPublicDashboard(id, pass);
      if (response.success) {
        if (response.data && !response.has_password) {
          const resItems = response.data.dashboard
            .filter((item) => item.dashboard_type_code !== null)
            .map((item) => ({
              data: {
                ...item,
                id: item.public_id,
              },
              type: item.dashboard_type_code,
              id: item.id,
              x: item.x,
              y: item.y,
              w: item.w,
              h: item.h,
              minH: 15,
            }));
          setItems(resItems);
          setData((pre) => ({
            ...pre,
            ...response.data,
            logo_url: response.organization_url_logo
              ? `${process.env.REACT_APP_API_URL}/${response.organization_url_logo}`
              : `https://eoffice.reecotech.com.vn/bitrix/templates/login/logo150.png`,
          }));
          setOpen(false);
          setPageLoading(false);
        } else {
          toggleModalPassword();
        }
      }
    } catch (error) {
      solveErrorPublicDashboard(error);
      clearDashboardPassword(id);
    } finally {
      setPageLoading(false);
    }
  };

  const getWorkspaceParameters = async (workspaceId) => {
    try {
      const res = await workspaceApi.getChartOptions(workspaceId);

      if (res.success) {
        var parameters = res.data?.station.map((itemStation) => {
          return {
            id: itemStation.id,
            value: itemStation.id,
            label: language === "en" ? itemStation.name : itemStation.name_vi,
            items: itemStation.parameter.map((item) => ({
              ...item,
              id: item.id,
              value: item.id,
              label: language === "en" ? item.name : item.name_vi,
              display_name: `${
                language === "en" ? itemStation.name : itemStation.name_vi
              } >> ${language === "en" ? item.name : item.name_vi}`,
            })),
          };
        });
        setConfigData((pre) => ({ ...pre, parameters }));
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const getWorkSpaceStations = async (workspaceId) => {
    try {
      const params = {};
      const res = await stationApi.getAllStations(workspaceId, params);
      if (res.success) {
        var stations = res.data;
        setConfigData((pre) => ({ ...pre, stations }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDashboardById = async (id) => {
    try {
      const res = await workspaceDashboardApi.getDashboard(id);
      if (res.success) {
        updateItem(id, res.data);
      }
    } catch (error) {
      // console.log(error);
    } finally {
      setItemsLoading((pre) => ({
        ...pre,
        [id]: false,
      }));
    }
  };

  const allowDragging = () => {
    setOnPressCtrlKey(false);
  };

  const controlKeyHandler = (e) => {
    if (e.ctrlKey || e.metaKey) {
      setOnPressCtrlKey(true);
    }
  };

  const onLayoutChange = () => {
    if (layout) {
      const dataLayout = {
        idView: screenMode,
        position: layout?.map((item) => ({
          id: item.i,
          w: item.w,
          h: item.h,
          x: item.x,
          y: item.y,
        })),
      };
      updatePosition(dataLayout);
    } else {
      swal({
        title: intl.formatMessage({
          id: "GENERAL.SOMETHING_WENT_WRONG",
        }),
        icon: "waning",
        button: intl.formatMessage({
          id: "CLIENT.GENERAL.OK_BUTTON",
        }),
      });
    }
  };

  const onRemoveItem = async (id) => {
    document.body.style.overflow = "hidden";
    swal({
      title: intl.formatMessage({
        id: "CLIENT.DASHBOARD.ITEM.DELETE_ITEM_WARNING_TITLE",
      }),
      text: intl.formatMessage({
        id: "CLIENT.DASHBOARD.ITEM.DELETE_ITEM_WARNING_TEXT",
      }),
      icon: "warning",
      // // dangerMode: true,
      buttons: [
        intl.formatMessage({
          id: "CLIENT.GENERAL.CANCEL_BUTTON",
        }),
        intl.formatMessage({
          id: "CLIENT.GENERAL.OK_BUTTON",
        }),
      ],
    })
      .then((yes) => {
        if (yes) {
          const handleDeleteItem = async () => {
            try {
              const newItems = items.filter((item) => item["id"] !== id);
              const res = await workspaceDashboardApi.deleteItem(id);
              if (res.success) {
                swal({
                  title: intl.formatMessage({
                    id: "CLIENT.DASHBOARD.ITEM.DELETE_ITEM_TITLE",
                  }),
                  text: intl.formatMessage({
                    id: "CLIENT.DASHBOARD.ITEM.DELETE_ITEM_DESCRIPTION",
                  }),
                  icon: "success",
                  button: intl.formatMessage({
                    id: "CLIENT.GENERAL.OK_BUTTON",
                  }),
                }).then(() => {
                  setItems(newItems);
                });
              } else {
                swal({
                  title: intl.formatMessage({
                    id: "CLIENT.DASHBOARD.ITEM.DELETE_ITEM_FAILED_TITLE",
                  }),
                  text: intl.formatMessage({
                    id: "CLIENT.DASHBOARD.ITEM.DELETE_ITEM_FAILED_DESCRIPTION",
                  }),
                  icon: "error",
                  button: intl.formatMessage({
                    id: "CLIENT.GENERAL.OK_BUTTON",
                  }),
                });
              }
            } catch (error) {
              swal({
                title: intl.formatMessage({
                  id: "CLIENT.DASHBOARD.ITEM.DELETE_ITEM_FAILED_TITLE",
                }),
                text: intl.formatMessage({
                  id: "CLIENT.DASHBOARD.ITEM.DELETE_ITEM_FAILED_DESCRIPTION",
                }),
                icon: "error",
                button: intl.formatMessage({
                  id: "CLIENT.GENERAL.OK_BUTTON",
                }),
              });
            }
          };
          handleDeleteItem();
        }
      })
      .then(() => {
        document.body.style.overflow = "auto";
      });
  };

  const onEditCardItem = (id, type) => {
    const item = items.find((item) => item.id === id);
    setActiveItem(item);
    toggleModal(type);
  };

  const updateDashboardItem = async (itemId, type, data) => {
    try {
      let res;
      if (type === "ATTACHMENT_IMAGE") {
        const form = new FormData();
        form.append("name_en", data.name_en);
        form.append("name_vi", data.name_vi);
        form.append("header_color", data.header_color);
        form.append("is_public", true);
        form.append("upload_type", data.upload_type);
        form.append("show_header", data.show_header);
        if (data.image) {
          form.append("image", data.imageFile);
        }
        form.append("link", data.link);
        form.append("image_format", data.image_format);
        form.append("scan_time", data.scan_time);
        res = await workspaceDashboardApi.updateFormItem(
          DASHBOARD_API_TYPE[type],
          itemId,
          form
        );
      } else {
        res = await workspaceDashboardApi.updateItem(
          DASHBOARD_API_TYPE[type],
          itemId,
          data
        );
      }
      if (res.success) {
        fetchDashboardById(itemId);
      }
    } catch (error) {
      // console.log(error);
    } finally {
      setItemsLoading((pre) => ({
        ...pre,
        [itemId]: false,
      }));
    }
  };

  const onEditItem = (type) => async (id, data) => {
    const postData = {
      ...data.dataUpdate,
      is_public: true,
    };

    setItemsLoading((pre) => ({
      ...pre,
      [id]: true,
    }));

    await updateDashboardItem(id, type, postData);
  };

  const getNewItemId = async (type) => {
    try {
      const response = await workspaceDashboardApi.createItem(
        DASHBOARD_API_TYPE[type],
        configData.dashboardId
      );
      if (response.success) {
        return response.data;
      }
    } catch (error) {
      // console.log(error);
    }
    return -1;
  };

  const onCreateItem = async (type, data) => {
    const id = await getNewItemId(type);
    const pos = findNearestPlace(layout);
    if (id !== -1) {
      setItems([
        ...items,
        {
          x: pos.x,
          y: pos.y,
          w: 1,
          h: 1,
          type,
          id,
          data: {
            id,
            dashboard_type_code: type,
            workspace_dashboard_id: configData.workspaceId,
            [`dashboard_${type.toLowerCase()}`]: {
              id: id,
            },
            show_header: true,
          },
        },
      ]);
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  function toggleFullScreen(elem) {
    // ## The below if statement seems to work better ## if ((document.fullScreenElement && document.fullScreenElement !== null) || (document.msfullscreenElement && document.msfullscreenElement !== null) || (!document.mozFullScreen && !document.webkitIsFullScreen)) {
    if (
      (document.fullScreenElement !== undefined &&
        document.fullScreenElement === null) ||
      (document.msFullscreenElement !== undefined &&
        document.msFullscreenElement === null) ||
      (document.mozFullScreen !== undefined && !document.mozFullScreen) ||
      (document.webkitIsFullScreen !== undefined &&
        !document.webkitIsFullScreen)
    ) {
      if (elem.requestFullScreen) {
        elem.requestFullScreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullScreen) {
        elem.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      }
      setIsFullScreen(true);
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
      setIsFullScreen(false);
    }
  }
  const getAllDashboardView = async () => {
    try {
      const req = await workspaceDashboardApi.getAllDashboardView();
      if (req?.success) {
        setDashboardView(req?.data);
        let maxScreen = {
          width: 0,
          id: 0,
        };
        req?.data?.forEach((element) => {
          if (element.width > maxScreen.width) {
            maxScreen = {
              width: element.width,
              id: element.id,
            };
          }
        });
        setScreenMode(maxScreen?.id);
        setViewMax(maxScreen?.id);
      }
    } catch (error) {}
  };

  const position = (item, type) => {
    let index = undefined;
    for (let i = 0; i < item?.length; i++) {
      if (item[i]?.dashboard_view_id === screenMode) {
        index = item[i];
        break;
      }
    }
    return {
      x: index?.x || defaultX,
      y: index?.y || defaultY,
      w: index?.w || defaultWH[screenMode ? screenMode : 3][type].w,
      h: index?.h || defaultWH[screenMode ? screenMode : 3][type].h,
    };
  };
  const initLayout = () => {
    const defaultLayout = items.map((item) => ({
      i: item.id.toString(),
      ...position(item?.data?.dashboard_position, item?.type),
      minH: item.minH,
    }));
    setLayout(defaultLayout);
  };
  const resetLayout = () => {
    setLayoutOld(false);
    const defaultLayout = items.map((item) => ({
      i: item.id.toString(),
      ...position(item?.data?.dashboard_position, item?.type),
      minH: item.minH,
    }));
    setLayout(defaultLayout);
    setReset(true);
  };
  return {
    history,
    open,
    publicSocketRef,
    socketRef,
    language,
    showMenu,
    showTopLogo,
    items,
    layout,
    openStates,
    configData,
    activeItem,
    data,
    realTimeData,
    pageLoading,
    itemsLoading,
    realTimeDataAlarm,
    onPressCtrlKey,
    isFullScreen,
    openPasswordModal,
    screenSize,
    layoutOld,
    screenMode,
    dashboardView,
    pageMode,
    isEditMode,
    viewMax,
    layout,
    initLayout,
    resetLayout,
    setIsFullScreen,
    toggleFullScreen,
    toggleModal,
    updatePosition,
    toggleMenu,
    setOnPressCtrlKey,
    setRealTimeDataAlarm,
    setItemsLoading,
    setPageLoading,
    setRealTimeData,
    setData,
    setActiveItem,
    setConfigData,
    setOpenStates,
    setItems,
    setShowTopLogo,
    setShowMenu,
    getAllDashboard,
    getWorkSpaceStations,
    getWorkspaceParameters,
    fetchDashboardById,
    allowDragging,
    controlKeyHandler,
    onLayoutChange,
    onEditCardItem,
    onRemoveItem,
    onEditItem,
    getNewItemId,
    onCreateItem,
    getAllPublicDashboard,
    setOpen,
    toggleModalPassword,
    handleCloseModalPassword,
    setIsEditMode,
    setLayoutDashboard,
    activeScreen,
    handleResize,
    getAllDashboardView,
    position,
    screenEdit,
    modalConfirmSave,
    setCheckPageMode,
    isRendering,
    setIsRendering,
    gridResizeEvent,
    setGridResizeEvent,
  };
};

export default useDashboard;
